import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 10px;
  overflow-x: scroll;
`;

const StyledTable = styled.table`
  display: grid;
  border-collapse: collapse;

  min-width: 100%;
  grid-template-columns:
    minmax(150px, 1.5fr)
    minmax(200px, 2fr)
    minmax(150px, 1fr)
    minmax(200px, 1.2fr);
`;
const StyledThead = styled.thead`
  display: contents;
`;
const StyledTh = styled.th`
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  background: #5e9952;
  text-align: center;
  font-weight: normal;
  font-size: 0.9rem;
  color: white;
  border-right: 1px solid #000;
  &:first-child {
    border-left: 1px solid #000;
  }
`;
const StyledTbody = styled.tbody`
  display: contents;
`;

const StyledTr = styled.tr`
  text-align: center;
  display: contents;

  &:nth-child(even) td {
    background: #f8f6ff;
  }
`;
const StyledTd = styled.td`
  border-right: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #000;
  padding: 15px;
  overflow: hidden;

  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
  :first-child {
    border-left: 1px solid #000;
  }
`;

const MatTable1 = () => {
  const renderedPods = (
    <StyledTr>
      <StyledTd className=''>Здание ЧОУ ДПО «Профф»</StyledTd>
      <StyledTd>ХМАО – Югра, г. Пыть-Ях, мкр. 6 «Пионерный», дом 30</StyledTd>
      <StyledTd>8</StyledTd>
      <StyledTd>8</StyledTd>
    </StyledTr>
  );

  return (
    <Container>
      <StyledTable>
        <StyledThead>
          <StyledTr>
            <StyledTh>НАИМЕНОВАНИЕ ОБЪЕКТА</StyledTh>
            <StyledTh>АДРЕС</StyledTh>
            <StyledTh>КОЛИЧЕСТВО ОБОРУДОВАННЫХ УЧЕБНЫХ КАБИНЕТОВ</StyledTh>
            <StyledTh>
              КОЛИЧЕСТВО ОБЪЕКТОВ ДЛЯ ПРОВЕДЕНИЯ ПРАКТИЧЕСКИХ ЗАНЯТИЙ
            </StyledTh>
          </StyledTr>
        </StyledThead>
        <StyledTbody>{renderedPods}</StyledTbody>
      </StyledTable>
    </Container>
  );
};

export default MatTable1;
