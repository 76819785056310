import React, { useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import MatTable1 from "../components/matTable1";
import MatTable2 from "../components/matTable2";
import MatTable3 from "../components/matTable3";
import Materialno from "../components/Materialno";
import Modal from "../components/modal";
import Zdorovie from "../components/zdorovie";

function Materials() {
  const [modalOpened, setModalOpened] = useState(false);
  const [zModalOpened, setZmodalOpened] = useState(false);

  const handleModalClose = () => {
    setModalOpened(false);
    setZmodalOpened(false);
  };
  return (
    <Layout>
      <SEO
        keywords={[
          `Материально техническое оснащение`,
          `Профф`,
          `Пыть-Ях`,
          `Профессиональное образование`,
        ]}
        title="Обеспечение"
      />

      <section className="flex flex-col items-center ">
        <h1 className=" text-center text-lg uppercase tracking-wider text-green-800 font-bold mb-4">
          НАЛИЧИЕ ОБОРУДОВАННЫХ УЧЕБНЫХ КАБИНЕТОВ, ОБЪЕКТОВ ДЛЯ ПРОВЕДЕНИЯ
          ПРАКТИЧЕСКИХ ЗАНЯТИЙ, В ТОМ ЧИСЛЕ ПРИСПОСОБЛЕННЫХ ДЛЯ ИСПОЛЬЗОВАНИЯ
          ИНВАЛИДАМИ И ЛИЦАМИ С ОГРАНИЧЕННЫМИ ВОЗМОЖНОСТЯМИ ЗДОРОВЬЯ
        </h1>
        <MatTable1 />

        <p className="p-2">
          НАЛИЧИЕ БИБЛИОТЕКИ.
          <br /> В здании ЧОУ ДПО «Профф» работает библиотека с учебной и
          методической литературой на бумажном носителе и/или CD. Кроме того, на
          официальном сайте ЧОУ ДПО «Профф» создана электронная библиотека;
          логин и пароль на доступ к которой выдается преподавателям и
          слушателям индивидуально. Ресурс содержит учебники, учебные и
          методические пособия, периодические издания, справочники, словари,
          энциклопедии, видео- и аудиоматериалы, иллюстрированные издания по
          направлениям обучения, производимого ЧОУ ДПО «Профф».
        </p>
        <p className="p-2 w-full">ОБЪЕКТЫ СПОРТА – отсутствуют. </p>
        <p className="p-2 w-full">
          ОБЕСПЕЧЕНИЕ ПИТАНИЕМ – организован питьевой режим (питьевая вода,
          горячий чай, кофе).
        </p>
        <h2 className="text-lg font-bold mt-4 text-green-800">
          ИНФОРМАЦИЯ ДЛЯ ЛЮДЕЙ С ОВЗ И ИНВАЛИДОВ
        </h2>
        <MatTable2 />

        <p className="p-2 w-full mt-4 text-center">
          УСЛОВИЯ ОХРАНЫ ЗДОРОВЬЯ ОБУЧАЮЩИХСЯ, В ТОМ ЧИСЛЕ ИНВАЛИДОВ И ЛИЦ С ОВЗ
        </p>
        <a
          className=" text-blue-600 mb-4"
          href="http://proff86.ru/template/Положение%20об%20охране%20здоровья%20слушателей%20ЧОУ%20ДПО%20«Профф»%20от%2018.01.2020%20года.pdf"
          rel="noreferrer"
          target="_blank"
        >
          Положение об охране здоровья слушателей ЧОУ ДПО «Профф» от 18.01.2020
          года
        </a>
        <h2 className=" text-green-800 font-bold text-center text-lg mt-4">
          ЭЛЕКТРОННЫЕ ОБРАЗОВАТЕЛЬНЫЕ РЕСУРСЫ, В ТОМ ЧИСЛЕ ПРИСПОСОБЛЕННЫЕ ДЛЯ
          ИСПОЛЬЗОВАНИЯ ИНВАЛИДАМИ И ЛИЦАМИ С ОГРАНИЧЕННЫМИ ВОЗМОЖНОСТЯМИ
          ЗДОРОВЬЯ
        </h2>

        <MatTable3 />
        <div
          className="w-full bg-white p-2 mt-6 rounded shadow-lg cursor-pointer"
          onClick={() => setModalOpened(true)}
        >
          <h2 className="text-green-700 text-2xl text-center">
            Материально-техническое обеспечение
          </h2>
        </div>
        <div
          className="w-full bg-white p-2 mt-6 rounded shadow-lg cursor-pointer"
          onClick={() => setZmodalOpened(true)}
        >
          <h2 className="text-green-700 text-2xl text-center">
            Положение об охране здоровья слушателей
          </h2>
        </div>
        {/* <Img fluid={data.structure2.childImageSharp.fluid} alt='Структура ' /> */}
      </section>
      {modalOpened && (
        <Modal onClose={handleModalClose}>
          <Materialno />
        </Modal>
      )}
      {zModalOpened && (
        <Modal onClose={handleModalClose}>
          <Zdorovie />
        </Modal>
      )}
    </Layout>
  );
}

export default Materials;
