import React from 'react';
import Stamp from '../images/stamp.png';
import Sign from '../images/sign.png';

const Zdorovie = () => {
  return (
    <div>
      <div className='flex  flex-col text-right items-end mb-8'>
        <p className=' font-bold'>Утверждаю:</p>
        <p>Генеральный директор</p>
        <p>ЧОУ ДПО «Профф»</p>
        <div className='relative'>
          <img
            src={Stamp}
            alt=''
            className='absolute'
            style={{ left: -150, top: -90, width: 250 }}
          />
          <img
            src={Sign}
            alt=''
            className='w-20 h-20 absolute '
            style={{ left: 40, top: -40 }}
          />
          _________________ Е.С. Протопопова
        </div>
        <p> «18» января 2020 г.</p>
      </div>

      <div className='text-center'>
        <p>
          <strong>Положение об охране здоровья слушателей</strong>
        </p>
        <p>
          <strong>
            Частного образовательного учреждения дополнительного
            профессионального образования «Профф»
          </strong>
        </p>
      </div>

      <div className='text-center font-bold p-4'>Общие положения</div>

      <p>
        <strong>1.1.</strong> Положение об охране здоровья слушателей Частного
        образовательного учреждения дополнительного профессионального
        образования «Профф» (далее – Учреждение, ЧОУ ДПО «Профф») разработано на
        основании ст. 41 Закона Российской Федерации «Об образовании» от
        29.12.2012 № 273-ФЗ и Устава.
      </p>
      <p>
        <strong>1.2.</strong> Охрана здоровья слушателей Учреждения включает в
        себя:
      </p>
      <p>
        * оказание первичной медико-санитарной помощи в порядке, установленном
        законодательством в сфере охраны здоровья;
      </p>
      <p>* организацию питания слушателей;</p>
      <p>* определение оптимальной учебной нагрузки, режима учебных занятий;</p>
      <p>
        * запрещение курения, употребления алкогольных, слабоалкогольных
        напитков, пива, наркотических средств и психотропных веществ, их
        прекурсоров и аналогов и других одурманивающих веществ;
      </p>
      <p>
        * обеспечение безопасности слушателей во время пребывания в Учреждении;
      </p>
      <p>* профилактику несчастных случаев;</p>
      <p>
        * проведение санитарно-противоэпидемических и профилактических
        мероприятий;
      </p>
      <p>
        * обучение специалистов Учреждения и/или педагогических работников
        навыкам оказания первой помощи.
      </p>
      <p>
        <strong>1.3.</strong> Организация охраны здоровья слушателей (за
        исключением оказания первичной медико-санитарной помощи, прохождения
        медицинских осмотров и диспансеризации) осуществляется Учреждением.
      </p>
      <p>
        <strong>1.4.</strong> Организация оказания первичной медико-санитарной
        помощи слушателям осуществляется органами исполнительной власти в сфере
        здравоохранения. Первичная медико-санитарная помощь оказывается
        слушателям медицинскими организациями.
      </p>
      <p>
        <strong>1.5.</strong> Учреждение при реализации образовательных программ
        создает условия для охраны здоровья слушателей, в том числе
        обеспечивает:
      </p>
      <p>* наблюдение за состоянием здоровья слушателей;</p>
      <p>
        * соблюдение государственных санитарно-эпидемиологических правил и
        нормативов;
      </p>
      <p>
        * расследование и учет несчастных случаев в порядке, установленном
        федеральным органом исполнительной власти, осуществляющим функции по
        выработке государственной политики и нормативно-правовому регулированию
        в сфере образования, по согласованию с федеральным органом
        исполнительной власти, осуществляющим функции по выработке
        государственной политики и нормативно-правовому регулированию в сфере
        здравоохранения.
      </p>

      <div className='text-center font-bold p-4'>
        Наблюдение за состоянием здоровья слушателей
      </div>
      <p>
        <strong>2.1.</strong> Наблюдение за состоянием здоровья слушателей
        проводится специалистами и/или преподавателями Учреждения.
      </p>
      <p>
        <strong>2.2.</strong> Повседневное медицинское наблюдение путем опроса
        проводится во время образовательного процесса. Особое внимание
        обращается на процесс адаптации слушателей к условиям учебной
        подготовки, воздействие учебной подготовки на состояние здоровья
        слушателей. О результатах наблюдения специалистом, курирующим процесс
        обучения, и/или преподавателем, проводящим занятия, докладывается
        руководителю учебного центра.
      </p>

      <div className='text-center font-bold p-4'>
        Проведение санитарно-гигиенических, профилактических и оздоровительных
        мероприятий
      </div>

      <p>
        <strong>3.1.</strong> Проведение санитарно-гигиенических,
        профилактических и оздоровительных мероприятий включает выполнение ряда
        требований:
      </p>
      <p>* системность деятельности по вопросам охраны здоровья;</p>
      <p>
        * взаимодействие Учреждения с органами исполнительной власти,
        правоохранительными органами, учреждениями дополнительного образования,
        культуры, здравоохранения и другими заинтересованными организациями по
        вопросам охраны и укрепления здоровья, безопасного образа жизни
        слушателей;
      </p>
      <p>
        * комплексный подход в оказании психолого-педагогической, социальной
        поддержки различных групп слушателей;
      </p>
      <p>
        * соответствие состояния и содержания территории, здания и помещений, а
        также их оборудования (водоснабжения, канализации, вентиляции,
        освещения) требованиям санитарных правил, требованиям пожарной
        безопасности, требованиям безопасности дорожного движения;
      </p>
      <p>
        {' '}
        * оснащение учебных кабинетов необходимым оборудованием и инвентарем в
        соответствии с требованиями санитарных правил для освоения
        образовательных программ;
      </p>
      <p>
        * культуру охраны здоровья специалистов и/или педагогических работников
        Учреждения;
      </p>
      <p>
        * соблюдение санитарных норм, предъявляемых к организации
        образовательного процесса (объем нагрузки по реализации образовательных
        программ, время на самостоятельную учебную работу, время отдыха,
        удовлетворение потребностей слушателей в двигательной активности), в том
        числе при введении в образовательный процесс педагогических инноваций;
      </p>
      <p>
        * использование форм, методов обучения и воспитания, педагогических
        технологий, адекватных возрастным возможностям и особенностям
        слушателей;
      </p>
      <p>
        * соблюдение норм двигательной активности при организации
        образовательного процесса в соответствии с требованиями санитарных
        правил;
      </p>
      <p>
        * обеспечение благоприятных психологических условий образовательной
        среды;
      </p>
      <p>
        * организацию взаимодействия Учреждения с организациями (учреждениями)
        культуры, здравоохранения, гражданской обороны, защиты населения и
        территорий от чрезвычайных ситуаций, правоохранительными органами;
      </p>
      <p>* наличие информационных ресурсов по вопросам здоровья;</p>
      <p>
        * проведение опросов на предмет удовлетворенности слушателей,
        педагогических работников Учреждения, комплексностью и системностью
        работы на предмет наличия благоприятного мнения об образовательном
        учреждении.
      </p>

      <div className='text-center font-bold p-4'>
        Расследование и учет несчастных случаев
      </div>
      <p>
        <strong>4.1.</strong> Расследование и учет несчастных случаев,
        произошедших в Учреждении со слушателями, проводится в соответствии с
        Приказом Минобрнауки России от 27.06.2017 № 602 «Об утверждении Порядка
        расследования и учета несчастных случаев с обучающимися во время
        пребывания в организации, осуществляющей образовательную деятельность».
      </p>
    </div>
  );
};

export default Zdorovie;
