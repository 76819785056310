import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 10px;
  overflow-x: scroll;
`;

const StyledTable = styled.table`
  display: grid;
  border-collapse: collapse;

  min-width: 100%;
  grid-template-columns:
    minmax(200px, 1.5fr)
    minmax(220px, 1.2fr)
    minmax(180px, 0.8fr)
    minmax(150px, 1fr);
`;
const StyledThead = styled.thead`
  display: contents;
`;
const StyledTh = styled.th`
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  background: #5e9952;
  text-align: center;
  font-weight: normal;
  font-size: 0.9rem;
  color: white;
  border-right: 1px solid #000;
  &:first-child {
    border-left: 1px solid #000;
  }
`;
const StyledTbody = styled.tbody`
  display: contents;
`;

const StyledTr = styled.tr`
  text-align: center;
  display: contents;

  &:nth-child(even) td {
    background: #f8f6ff;
  }
`;
const StyledTd = styled.td`
  border-right: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #000;
  padding: 15px;
  overflow: hidden;

  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
  :first-child {
    border-left: 1px solid #000;
  }
`;

const data = [
  {
    title: 'Электронная библиотека ЧОУ ДПО «Профф»',
    url: 'http://proff86test.ru/',
    place: 'Официальный сайт ЧОУ ДПО «Профф»',
    schedule: 'круглосуточно',
  },
  {
    title: 'Информационно-правовой портал "Гарант"	',
    url: 'http://www.garant.ru/',
    place: '-',
    schedule: 'круглосуточно',
  },
  {
    title: 'Некоммерческие интернет-версии системы «КонсультантПлюс»	',
    url: 'https://www.consultant.ru/online/',
    place: '-',
    schedule: 'круглосуточно',
  },
  {
    title: 'Федеральный центр образовательного законодательства',
    url: 'http://www.lexed.ru/',
    place: '-',
    schedule: 'круглосуточно',
  },
  {
    title: 'Федеральный портал проектов нормативных правовых актов',
    url: 'https://regulation.gov.ru/',
    place: '-',
    schedule: 'круглосуточно',
  },
  {
    title: 'Электронный фонд правовой и нормативно-технической документации',
    url: 'http://docs.cntd.ru/',
    place: '-',
    schedule: 'круглосуточно',
  },
  {
    title: 'Федеральный портал «Российское образование»',
    url: 'http://www.edu.ru/',
    place: '-',
    schedule: 'круглосуточно',
  },
  {
    title:
      'Информационная система «Единое окно доступа к образовательным ресурсам»',
    url: 'http://window.edu.ru/',
    place: '-',
    schedule: 'круглосуточно',
  },
  {
    title: 'Единая коллекция цифровых образовательных ресурсов',
    url: 'http://school-collection.edu.ru/',
    place: '-',
    schedule: 'круглосуточно',
  },
  {
    title: 'Федеральный центр информационно-образовательных ресурсов',
    url: 'http://fcior.edu.ru/',
    place: '-',
    schedule: 'круглосуточно',
  },
];

const MatTable3 = () => {
  const renderedPods = data.map(({ title, url, place, schedule }) => (
    <StyledTr key={title}>
      <StyledTd className=''>{title}</StyledTd>
      <StyledTd>
        <a
          className='text-blue-600'
          href={url}
          rel='noreferrer'
          target='_blank'
        >
          {url}
        </a>
      </StyledTd>
      <StyledTd>{place}</StyledTd>
      <StyledTd>{schedule}</StyledTd>
    </StyledTr>
  ));

  return (
    <Container>
      <StyledTable>
        <StyledThead>
          <StyledTr>
            <StyledTh>НАЗВАНИЕ ЭОР</StyledTh>
            <StyledTh>URL (АДРЕС В ИНТЕРНЕТЕ)</StyledTh>
            <StyledTh>МЕСТОНАХОЖДЕНИЕ</StyledTh>
            <StyledTh>ГРАФИК ДОСТУПА</StyledTh>
          </StyledTr>
        </StyledThead>
        <StyledTbody>{renderedPods}</StyledTbody>
      </StyledTable>
    </Container>
  );
};

export default MatTable3;
